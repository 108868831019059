import Image0   from "../../assets/img/portfolio/project-1.jpg";
import Image1   from "../../assets/img/portfolio/project-2.jpg";
import Image2   from "../../assets/img/portfolio/project-3.jpg";
import Image3   from "../../assets/img/portfolio/project-4.jpg";
import Image4   from "../../assets/img/portfolio/project-5.jpg";
import Image5   from "../../assets/img/portfolio/project-6.jpg";
import Image6   from "../../assets/img/portfolio/project-7.jpg";
import Image7   from "../../assets/img/portfolio/project-8.jpg";
import Image8   from "../../assets/img/portfolio/project-9.jpg";
import Image9   from "../../assets/img/portfolio/project-10.jpg";
import Image11  from "../../assets/img/portfolio/project-11.jpg";
import Image10  from "../../assets/img/portfolio/project-12.jpg";
import Image12  from "../../assets/img/portfolio/project-13.jpg";
import Image13  from "../../assets/img/portfolio/project-14.jpg";
import Image14  from "../../assets/img/portfolio/project-15.jpg";
import Image15  from "../../assets/img/portfolio/project-16.jpg";
import Image16  from "../../assets/img/portfolio/project-17.jpg";
import Image17  from "../../assets/img/portfolio/project-18.jpg";
import Image18  from "../../assets/img/portfolio/project-19.jpg";
import Image19  from "../../assets/img/portfolio/project-20.jpg";
import Image20  from "../../assets/img/portfolio/project-21.jpg";
import Image21  from "../../assets/img/portfolio/project-22.jpg";
import Image22  from "../../assets/img/portfolio/project-23.jpg";
import Image23  from "../../assets/img/portfolio/project-24.jpg";
import Image24  from "../../assets/img/portfolio/project-25.jpg";
import Image25  from "../../assets/img/portfolio/project-26.jpg";
import Image26  from "../../assets/img/portfolio/project-27.jpg";
import Image27  from "../../assets/img/portfolio/project-28.jpg";
import Image28  from "../../assets/img/portfolio/project-29.jpg";
import Image29  from "../../assets/img/portfolio/project-30.jpg";
import Image30  from "../../assets/img/portfolio/project-31.jpg";
import Image31  from "../../assets/img/portfolio/project-32.jpg";
import Image32  from "../../assets/img/portfolio/project-33.jpg";
import Image33  from "../../assets/img/portfolio/project-34.jpg";
import Image34  from "../../assets/img/portfolio/project-35.jpg";
import Image35  from "../../assets/img/portfolio/project-36.jpg";
import Image36  from "../../assets/img/portfolio/project-37.jpg";
import Image37  from "../../assets/img/portfolio/project-38.jpg";
import Image38  from "../../assets/img/portfolio/project-39.jpg";
import Image39  from "../../assets/img/portfolio/project-40.jpg";
import Image40  from "../../assets/img/portfolio/project-41.jpg";
import Image41  from "../../assets/img/portfolio/project-42.jpg";
import Image42  from "../../assets/img/portfolio/project-43.jpg";
import Image43  from "../../assets/img/portfolio/project-44.jpg";
import Image44  from "../../assets/img/portfolio/project-45.jpg";
import Image45  from "../../assets/img/portfolio/project-46.jpg";
import Image46  from "../../assets/img/portfolio/project-47.jpg";
import Image47  from "../../assets/img/portfolio/project-48.jpg";
import Image48  from "../../assets/img/portfolio/project-49.jpg";
import Image49  from "../../assets/img/portfolio/project-50.jpg";
import Image50  from "../../assets/img/portfolio/project-51.jpg";
import Image51  from "../../assets/img/portfolio/project-52.jpg";
import Image52  from "../../assets/img/portfolio/project-53.jpg";
import Image53  from "../../assets/img/portfolio/project-54.jpg";
import Image54  from "../../assets/img/portfolio/project-55.jpg";
import Image55  from "../../assets/img/portfolio/project-56.jpg";
import Image56  from "../../assets/img/portfolio/project-57.jpg";
import Image57  from "../../assets/img/portfolio/project-58.jpg";
import Image58  from "../../assets/img/portfolio/project-59.jpg";
import Image59  from "../../assets/img/portfolio/project-60.jpg";
import Image60  from "../../assets/img/portfolio/project-61.jpg";
import Image61  from "../../assets/img/portfolio/project-62.jpg";
// project = Country, client = Location, language = Month preview = Time of Day
const PortfolioData = [
  {
    id: 1,
    type: "Grad Photo",
    image: Image0,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "December",
        time_of_day: "Afternoon",
        // link: "https://haoyang-personal-website.s3.amazonaws.com/static/media/project-1.7ac7795ea21ff6f2443a.jpg",
      },
    ],
  },
  {
    id: 2,
    type: "Grad Photo",
    image: Image1,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "December",
        time_of_day: "Afternoon",
        // link: "https://haoyang-personal-website.s3.amazonaws.com/static/media/project-2.345a27309815e815c5b8.jpg",
      },
    ],
  },
  {
    id: 3,
    type: "Grad Photo",
    image: Image2,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "December",
        time_of_day: "Afternoon",
        // link: "https://www.envato.com",
      },
    ],
  },
  {
    id: 4,
    type: "Grad Photo",
    image: Image3,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "December",
        time_of_day: "Afternoon",
        // link: "https://www.videohive.net",
      },
    ],
  },
  {
    id: 5,
    type: "Grad Photo",
    image: Image4,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "December",
        time_of_day: "Afternoon",
        // link: "https://themeforest.net/item/deski-saas-software-react-template/33799794",
      },
    ],
  },
  {
    id: 6,
    type: "Puerto Rico",
    image: Image5,
    tag: ["landscape"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "Puerto Rico",
        location: "Fajardo",
        month: "March",
        time_of_day: "Evening",
        // link: "https://www.pexels.com",
      },
    ],
  },
  {
    id: 7,
    type: "Puerto Rico",
    image: Image6,
    tag: ["landscape"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "Puerto Rico",
        location: "Old San Juan",
        month: "March",
        time_of_day: "Afternoon",
        // link: "https://www.facebook.com/ibthemes",
      },
    ],
  },
  {
    id: 8,
    type: "Portrait",
    image: Image7,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "Puerto Rico",
        location: "Luquillo Beach",
        month: "March",
        time_of_day: "Afternoon",
        // link: "https://haoyang-personal-website.s3.amazonaws.com/static/media/project-8.e9d2920cb16703f807be.jpg",
      },
    ],
  },
  {
    id: 9,
    type: "Portrait",
    image: Image8,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "Puerto Rico",
        location: "Luquillo Beach",
        month: "March",
        time_of_day: "Evening",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },
  {
    id: 10,
    type: "Portrait",
    image: Image9,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "Puerto Rico",
        location: "Old San Juan",
        month: "March",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },
  {
    id: 11,
    type: "Portrait",
    image: Image10,
    tag: ["landscape"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "Puerto Rico",
        location: "Old San Juan",
        month: "March",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },
  {
    id: 12,
    type: "Puerto Rico",
    image: Image11,
    tag: ["portraits", "street photography"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "Puerto Rico",
        location: "Old San Juan",
        month: "March",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },
  {
    id: 13,
    type: "Puerto Rico",
    image: Image12,
    tag: ["landscape"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "Puerto Rico",
        location: "El Yunque",
        month: "March",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 14,
    type: "BOW Fashion Show 2022",
    image: Image13,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "April",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 15,
    type: "BOW Fashion Show 2022",
    image: Image14,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "April",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 16,
    type: "BOW Fashion Show 2022",
    image: Image15,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "April",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 17,
    type: "Quincy Quarries",
    image: Image16,
    tag: ["street photography", "portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Quincy MA",
        month: "April",
        time_of_day: "Night",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 18,
    type: "Quincy Quarries",
    image: Image17,
    tag: ["portraits, street photography"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Quincy MA",
        month: "April",
        time_of_day: "Night",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 19,
    type: "Quincy Quarries",
    image: Image18,
    tag: ["portraits", "street photography"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Quincy MA",
        month: "April",
        time_of_day: "Night",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 20,
    type: "Quincy Quarries",
    image: Image19,
    tag: ["portraits", "street photography"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Quincy MA",
        month: "April",
        time_of_day: "Night",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 21,
    type: "Grad Photo",
    image: Image20,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 22,
    type: "Grad Photo",
    image: Image21,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 23,
    type: "Grad Photo",
    image: Image22,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 24,
    type: "Grad Photo",
    image: Image23,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 25,
    type: "Grad Photo",
    image: Image24,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 26,
    type: "Grad Photo",
    image: Image25,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 27,
    type: "Grad Photo",
    image: Image26,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 28,
    type: "Grad Photo",
    image: Image27,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 29,
    type: "Grad Photo",
    image: Image28,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 30,
    type: "Grad Photo",
    image: Image29,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 31,
    type: "Grad Photo",
    image: Image30,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 32,
    type: "Grad Photo",
    image: Image31,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 33,
    type: "Grad Photo",
    image: Image32,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 34,
    type: "Grad Photo",
    image: Image33,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 35,
    type: "Grad Photo",
    image: Image34,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },
  {
    id: 36,
    type: "Grad Photo",
    image: Image35,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 37,
    type: "Grad Photo",
    image: Image36,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 38,
    type: "Grad Photo",
    image: Image37,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 39,
    type: "Grad Photo",
    image: Image38,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Babson College",
        month: "May",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 40,
    type: "New York",
    image: Image39,
    tag: ["landscape"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Whitestone Bridge",
        month: "June",
        time_of_day: "Night",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 41,
    type: "New York",
    image: Image40,
    tag: ["landscape"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "The Vessel",
        month: "July",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 42,
    type: "New York",
    image: Image41,
    tag: ["landscape"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "The Brooklyn Bridge",
        month: "July",
        time_of_day: "Evening",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 43,
    type: "New York",
    image: Image42,
    tag: ["portraits"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "Long Island",
        month: "October",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 44,
    type: "New York",
    image: Image43,
    tag: ["portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "Long Island",
        month: "October",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 45,
    type: "New York",
    image: Image44,
    tag: ["portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "Long Island",
        month: "October",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 46,
    type: "New York",
    image: Image45,
    tag: ["landscape", "street photography"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "USA",
        location: "The Flatiron Building",
        month: "October",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 47,
    type: "New York",
    image: Image46,
    tag: ["street photography","portraits"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "USA",
        location: "New York City",
        month: "October",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 48,
    type: "New York",
    image: Image47,
    tag: ["street photography","portraits"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "USA",
        location: "New York City",
        month: "October",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 49,
    type: "Singapore",
    image: Image48,
    tag: ["landscape"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "Singapore",
        location: "The Merlion",
        month: "January",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 50,
    type: "Singapore",
    image: Image49,
    tag: ["landscape"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "Singapore",
        location: "Marina Barrage",
        month: "January",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 51,
    type: "Singapore",
    image: Image50,
    tag: ["landscape"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "Singapore",
        location: "Marina Barrage",
        month: "January",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 52,
    type: "Honda Type-R",
    image: Image51,
    tag: ["street photography"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "Thailand",
        location: "Watthana",
        month: "January",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 53,
    ttype: "Honda Type-R",
    image: Image52,
    tag: ["street photography"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "Thailand",
        location: "Watthana",
        month: "January",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 54,
    type: "Honda Type-R",
    image: Image53,
    tag: ["street photography"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "Thailand",
        location: "Watthana",
        month: "January",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 55,
    type: "New Years in Singapore",
    image: Image54,
    tag: ["landscape"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "Singapore",
        location: "Chinatown",
        month: "February",
        time_of_day: "Night",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 56,
    type: "New Years in Singapore",
    image: Image55,
    tag: ["landscape"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "Singapore",
        location: "Chinatown",
        month: "February",
        time_of_day: "Night",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 57,
    type: "Singapore",
    image: Image56,
    tag: ["landscape"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "Singapore",
        location: "Gardens by the Bay",
        month: "February",
        time_of_day: "Night",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 58,
    type: "Thailand",
    image: Image57,
    tag: ["landscape"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "Thailand",
        location: "The Ancient City",
        month: "February",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 59,
    type: "Thailand",
    image: Image58,
    tag: ["landscape"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "Thailand",
        location: "The Ancient City",
        month: "February",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 60,
    type: "Thailand",
    image: Image59,
    tag: ["landscape"],
    delayAnimation: "200",
    modalDetails: [
      {
        country: "Thailand",
        location: "The Ancient City",
        month: "February",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 61,
    type: "Thailand",
    image: Image60,
    tag: ["landscape"],
    delayAnimation: "0",
    modalDetails: [
      {
        country: "Thailand",
        location: "The King's Palace",
        month: "February",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },{
    id: 62,
    type: "Thailand",
    image: Image61,
    tag: ["landscape"],
    delayAnimation: "100",
    modalDetails: [
      {
        country: "Thailand",
        location: "The King's Palace",
        month: "February",
        time_of_day: "Afternoon",
        // link: "https://www.behance.net/ib-themes",
      },
    ],
  },
];

export default PortfolioData;
