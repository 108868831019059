import React from "react";

const experienceContent = [
  {
    year: "May 2023 - August 2023",
    position: "3D Driver Group Intern",
    compnayName: "AMD",
    details: `Summer 2023 3D Driver Group Intern working on optimizing rasterazation performance and adding DirectX 12 ray tracing support to AMD's GPU drivers`,
  },
  {
    year: "Sep 2022 - Dec 2022",
    position: "Management Consulting Analyst",
    compnayName: "Baskin-Robbins",
    details: `Led a team of 8 to conduct market research and data analytics to present three strategic plans to Baskin Robbins leadership using Tableau, R, and Python`,
  },
  {
    year: "May 2022 - Aug 2022",
    position: "HitS Platform Data Product Manager Intern",
    compnayName: "WuXi Apptec",
    details: `Led a cross-functional team of 4 Biologists and Software engineers to develop an AWS Lambda-based image processor for improving ML image recognition accuracy`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
