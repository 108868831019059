import React from "react";
// import img1 from "../../../assets/img/portfolio/project-1.jpg";
// import img2 from "../../../assets/img/portfolio/project-2.jpg";
// import portfolioVideo from "../../../assets/img/portfolio/video.mp4";
import CloseImg from "../../../assets/img/cancel.svg";
import PortfolioData from "../portfolioData";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Modal = ({ modalId, setGetModal }) => {
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   draggable: true,
  // };
  if (modalId === 1) {
    return (
      <div className="modal_portfolio ">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 2) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
          //         })}
          //       </div>
          //       <figure className="modal__img videocontainer">
          //         <iframe
          //           src="https://www.youtube.com/embed/7e90gBu4pas"
          //           title="YouTube video player"
          //           className="youtube-video"
          //           allowFullScreen
          //         ></iframe>
          //       </figure>

          //       <button
          //         className="close-modal"
          //         onClick={() => setGetModal(false)}
          //       >
          //         <img src={CloseImg} alt="portfolio project demo" />
          //       </button>
          //     </div>
          //   );
          // })}
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 3) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  {/* <Slider {...settings}>
                    <div> */}
                      <img src={item.image} alt="portfolio project demo" />
                    {/* </div>
                    <div>
                      <img src={img1} alt="portfolio project demo" />
                    </div>
                    <div>
                      <img src={img2} alt="portfolio project demo" />
                    </div>
                  </Slider> */}
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 4) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                {/* <figure className="modal__img">
                   <img src={item.image} alt="portfolio project demo" />
                  <video
                    id="video"
                    className="responsive-video"
                    controls
                    poster={item.image}
                  >
                    <source src={portfolioVideo} type="video/mp4" />
                    <source src="img/" type="video/mp4" />
                  </video>
                </figure>  */}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 5) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */} 
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 6) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 7) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 8) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 9) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 10) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 11) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 12) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 13) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 14) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 15) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 16) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 17) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 18) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 19) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 20) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 21) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 22) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 23) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 24) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 25) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 26) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 27) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 28) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 29) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 30) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 31) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 32) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 33) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 34) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 35) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 36) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 37) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 38) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 39) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 40) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 41) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 42) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 43) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 44) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 45) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 46) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 47) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 48) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 49) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 50) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 51) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 52) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 53) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 54) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 55) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 56) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 57) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 58) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 59) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 60) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 61) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 62) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 63) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 64) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 65) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 66) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 67) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 68) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 69) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 70) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 71) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 72) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 73) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 74) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 75) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            //
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-1">{item.type}</h2>
                <div className="modal__details">
                  {item.modalDetails.map((details, i) => {
                    return (
                      <div key={i} className="row open-sans-font">
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-globe pr-2"></i>
                          Country :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.country}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-map-marker pr-2"></i>
                          Location :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.location}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-calendar pr-2"></i>
                          Month :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.month}
                          </span>
                        </div>
                        <div className="col-12 col-sm-6 mb-1">
                          <i className="fa fa-clock-o pr-2"></i>
                          Time of day :{" "}
                          <span className="ft-wt-600 uppercase">
                            {details.time_of_day}
                          </span>
                          {/* <a
                            className="preview-link"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            href={details.time_of_day}
                          >
                            {details.time_of_day}
                          </a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } 
};

export default Modal;
