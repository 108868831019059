import React from "react";

const personalInfoContent = [
  { meta: "FIRST NAME", metaInfo: "Haoyang" },
  { meta: "LAST NAME", metaInfo: "Zhang" },
  { meta: "AGE", metaInfo: "21" },
  { meta: "NATIONALITY", metaInfo: "American" },
  { meta: "ADDRESS", metaInfo: "Boston, MA" },
  { meta: "PHONE", metaInfo: "+1 (617)-510-1601" },
  { meta: "PERSONALITY", metaInfo: "ENFP-A" },
  { meta: "LANGUAGES", metaInfo: "English, Chinese, Latin" },
  { meta: "ZODIAC", metaInfo: "Virgo" },
  //{ meta: "GITHUB", metaInfo: "github.com/hzhang0921" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
