import React from "react";

const educationContent = [
  {
    year: "2020 - Present",
    degree: "BUSINESS DEGREE",
    institute: "BABSON COLLEGE",
    details: `Wellesley, Massachusetts | Courses: Negotiations, Problem Solving with Software, Strategic Problem Solving, Macro/Microeconomics, Business Analytics I, II, III, Operations Managemnt, Managerial Accounting, Finance I`,
  },
  {
    year: "2023",
    degree: "STUDY ABROAD",
    institute: "SINGAPORE MANAGEMENT UNIVERSITY",
    details: `Singapore, Singapore | Courses: Designing Communication for Behavioral Change, Intercultural Communication, Internal Communication, Contemporary Asia`,
  },
  {
    year: "2014 - 2020",
    degree: "HIGH SCHOOL DIPLOMA",
    institute: "BOSTON LATIN SCHOOL",
    details: `Boston, Massachusetts | Courses: AP Computer Science Java, AP BC Calculus, AP AB Calculus, AP Writing, AP Physics Mechanics, AP Biology, Chemistry`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-graduation-cap"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
